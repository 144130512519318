import { gql } from 'apollo-boost';

export const GET_SECTIONS = gql`
  query getSections{
    sections {
      title__ca
      id
      title__en
      title__es
      content__en
      content__es
      content__ca
      testimonial__en
      testimonial__es
      testimonial__ca
      name_id
      image {
        url
      }
      icon_small {
        url
      }
      icon_big {
        url
      }
      subsections {
        id
        title__en
        title__ca
        title__es
        thumbnail{
          url
        }
        atHome
      }
    }
  }
`;

export const GET_TESTIMONIALS = gql`
  query getTestimonials{
    testimonials {
      id
      Author
      text_en
      text_es
      text_ca
      occupation_en
      occupation_es
      occupation_ca
      url
    }
  }
`;

export const GET_TEAM = gql`
  query getTeam{
    teams {
      id
      name
      profile_ca
      profile_en
      profile_es
      picture{
        url
      }
    }
  }
`;

export const GET_PROJECTS = gql`
  query getprojects($start: Int, $limit: Int){
    projects(start: $start, limit: $limit) {
    id
    category
    title__en
    title__ca
    title__es
    thumbnail{
        url
      }
    }
  }
`;

export const GET_PROJECT = gql`
  query getproject($id: ID!) {
    project(id: $id) {
      id
      title__en
      title__es
      title__ca
      content__ca
      content__en
      content__es
      category
      hero_image{
        url
      }
      thumbnail{
        url
      }
    }
  }
`;

export const GET_SUBSECTION = gql`
  query getsubsection($id: ID!) {
    subsection(id: $id) {
      id
      title__en
      title__es
      title__ca
      content__ca
      content__en
      content__es
      category
      hero_image{
        url
      }
      thumbnail{
        url
      }
      projects {
        thumbnail{
          url
        }
        title__en
        title__es
        title__ca
      }
    }
  }
`;


