import React from 'react'
import { Header, Footer } from "../../components/index.components";
import './NotFound.scss';
import { Link } from 'react-router-dom';

const NotFound = ({ asFilterSections }) => {
  return (
    <>
      <Header sections={asFilterSections} headerStyle={{ top: 0 }} />
      <div className="not-found">
        <div className="not-found__container">
          <span className="not-found__number">404</span>
          <span>Sorry, page not found</span>
          <Link to="/"><button> Go Home</button></Link>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default NotFound
