import React from 'react';
import src_image from '../../../assets/images/ubora_quienes_somos_ines.jpg';
import './Memorial.scss'
const Memorial = ({ title, text }) => {
  return (
    <div className="memorial__container">
      <div className="memorial__image">

        <img src={src_image} alt="foto ines romero" />
      </div>
      <div className="memorial__bg">
        <h3 className="memorial__title">
          {title}
        </h3>
        <p className="memorial__text">
          {text}
        </p>
        <a href="https://homenajeaines.wordpress.com/" target="_blank" rel="noopener noreferrer">homenajeaines.wordpress.com</a>
      </div>

    </div>
  )
}

export default Memorial
