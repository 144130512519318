import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';

import Carousel from 'react-bootstrap/Carousel'
import './CarouselMenu.scss'

const CarouselMenu = ({ items, onClick }) => {
  return (
    <div className="carousel">
      <Carousel>
        {items.map(section => (
          <Carousel.Item key={section.id}>

            <Link smooth to={`/#${section.id}`} onClick={onClick}>
              <img
                className="d-block w-100"
                src={section.icon_big.url}
                alt={section.title__en || section.title__es || section.title__ca}
              />
            </Link>

            <Carousel.Caption>
              <h3>{section.title__en || section.title__es || section.title__ca}</h3>
            </Carousel.Caption>

          </Carousel.Item>
        ))}
      </Carousel>
    </div>
  )
}

export default CarouselMenu
