import React from 'react'
import './SideMenu.scss';
import { HashLink as Link, NavHashLink as NavLink } from 'react-router-hash-link';
import localization from "../../localization";
import { connect } from 'react-redux';
import { ToogleMenu } from '../../services/actions/ui.actions';
import { FaTimes, FaRegEnvelope, FaAngleRight } from "react-icons/fa";
import { CarouselMenu } from "../index.components";


const SideMenu = ({ dispatch, ux, asFilterSections }) => {

  const { isOpen, yPosition } = ux
  const onOpenMenuButtonPress = (top) => {
    if (top !== "top") {
      window.scrollTo(0, yPosition - 5)

    } else {
      window.scrollTo(0, 0)

    }
    dispatch(ToogleMenu())
  }
  const handelClosedMenu = () => {
    dispatch(ToogleMenu())

  }
  return (
    <div className={isOpen ? 'side-menu isOpen' : 'side-menu'}>
      <div className="side-menu__head">
        <button onClick={(e) => onOpenMenuButtonPress(e)} className="side-menu__head_close">
          <FaTimes size={'30px'} />
        </button>
        <Link smooth to={`/#footer`} onClick={(e) => onOpenMenuButtonPress(e)} ><FaRegEnvelope size={'22px'} /></Link>
      </div>
      <div className="side-menu__carousel">
        <h3>{localization.home.services.title}</h3>
        <CarouselMenu items={asFilterSections} onClick={handelClosedMenu} />
      </div>
      <div className="side-menu__menu">
        <h3>{localization.menu.titleMobile}</h3>
        <ul>
          <li >
            <NavLink onClick={(e) => onOpenMenuButtonPress('top')} to="/what">{localization.menu.what} <FaAngleRight size={'22px'} /></NavLink>
          </li>
          <li>
            <NavLink onClick={(e) => onOpenMenuButtonPress('top')} to="/appoarch">{localization.menu.appoarch} <FaAngleRight size={'22px'} /></NavLink>
          </li>
          <li>
            <NavLink onClick={(e) => onOpenMenuButtonPress('top')} to="/projects">{localization.menu.how} <FaAngleRight size={'22px'} /></NavLink>
          </li>
          <li>
            <NavLink onClick={(e) => onOpenMenuButtonPress('top')} to="/team">{localization.menu.team} <FaAngleRight size={'22px'} /></NavLink>
          </li>
        </ul>
      </div>

    </div>
  )
}

const mapStateToProps = (state) => {

  return state
}
const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SideMenu)