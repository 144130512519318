const en = {
  home: {
    services: {
      title: 'OUR SERVICES',
      testimonial: 'Our motivation comes through working together with people and organisations in their journey towards development and change. We want to share with you our experience and knowledge on organisational practices and on innovative methodological approaches related to M&E. We invite you to explore our website and find out more about us and the services we offer.'
    },
    text: "The UBORA team aims to improve organisational practices and strategic planning of  organisations, as well as the internal M&E in order to improve the subsections and   programs contribution towards social change and social transformation. <br><br> We provide technical assistance for the development of capacities and the promotion of sound organisational practices.",
    linkImage: '/assets/images/logo_ubora_white_eng.png',
    footer: {
      name: "Name *",
      email: "Email *",
      boxtext: "Write here your message *",
      required: "* Fields are required.",
      talk: "Hello, let's talk",
      button: "Send",
      terms: "Legal Terms",
      policies: "Privacy Policies",
      cookies: "Cookies",
      errorName: 'Name is required',
      errorEmail: 'Email is required and must be a valid email',
      errorMessage: 'Message is required and must to be longer than 40 characters',
    }
  },
  menu: {
    buttonName: 'Us',
    titleMobile: "Know us",
    what: 'What UBORA stands for?',
    appoarch: 'Our approach',
    how: "The way we work",
    team: 'Team'
  },
  what: {
    title: "WHAT IS UBORA?",
    text: "In Kiswahili “ubora” means “do things right, being eager to, excellence” which leads us here to a system of learning and good organisational practices.<br/> <br/>    The term “good practices” has different meanings, but could be defined as a certain status in which all elements or components of an organisation (strategy, operational aspects, structure and organisational culture) work closely in an efficient manner as to reach long-term measurable advances.<br/> <br/> UBORA, organisational learning and management quality, is a Catalan organisation offering services and guidance mainly to governmental bodies & local authorities and CSOs. UBORA provides capacity-building and organisational learning’ services, so that is, facilitating institutional strengthening programmes adapted to each organisation (organisational and operational  dimension).<br/> <br/>  It also embraces a diversity of actors and visions as international associates and collaborators, thus enlarging its networking capacity in different areas of expertise.<br/> <br/> UBORA gives advice to organisations helping them to reflect on who they are, how things are done and organised, how internal management should be improved and, above all, use all these knowledge as a learning-oriented tool in order to upgrade their organisational practices.<br/>  We truly believe that M&E processes are crucial. In this view, UBORA uses innovative approaches allowing us to reflect on the performance of our actions and programmes and thus enhancing our practices as to achieve tangible outcomes."
  },
  approach: {
    title: "Our approach",
    quote: "Argonauts used to say:<br/> “Sailing is necessary, Living is not necessary”.<br/>I want to keep the spirit of such statement, but transformed.<br/>The way is has to match its essence with what I am:<br/>Living is not necessary, what it is is creating.",
    autor: "FERNANDO PESSOA",
    text: "We believe that, as Argonauts stated, “sailing is necessary”. In this view, sailing will help us overcome all obstacles of all seas, but in order to do that we need a new map to orientate us. We think that, as Fernando Pessoa reflected upon, what is necessary is creating, so that is, learning and “unlearning”, understand and adapt to new opportunities and emerging context, which proves to be crucial in this particular field, International Cooperation and Education for Development.",
    subtitle: "Our work revolves around the following:",
    pillars: {
      first: "We use and combine different methodological approaches, such as the “Outcome Mapping”, the “Theory of Change”, the “Most Significant Change” or even group facilitation based on “Process Works” and “Reflect-Action”, among others.",
      second: "We facilitate knowledge sharing between different Development approaches and practices, thus enabling the creation of new communication channels more wide open, more participatory and horizontally oriented as to recognise and place value to experiences, practices and knowledge coming from southern contexts and local realities",
      thrid: "We lay down bridges between organisational management knowledge and quality management, using our organisational capacity background and expertise in the field of International Cooperation for Development.",
      fourth: "We work with a collaborative approach among all different actors and stakeholders in complex environments where Development takes place (NGOs, Public Administration, social entrepreneurship initiatives, the education community,..)."
    },
    testtimonial_title: "TESTIMONY"

  },
  team: {
    title: "TEAM",
    text_one: "&#8226 We count on a highly experienced and long record in the field of International Development, with a clear international projection.<br>	&#8226 We work providing contextualised solutions for every need. Not from a theoretical but applied approach.<br>	&#8226 We work in collaboration with a wide network of organizations and professionals from various countries with whom we share our approach to the work.",
    text_two: "UBORA is established as an interdisciplinary space in which professionals from different areas converge with consolidated international development cooperation experience. It has a team of people with experience working on international cooperation and with different stakeholders: Donors and Public Administration, CSOs, Universities from northern and southern countries, etc.)",
    title_memorial: 'En memoria a Inés Romero',
    text_memorail: 'La recordaremos por su empatía, rigurosidad y pasión profesional, por su meticulosidad, cuidado y vasto conocimiento, por su forma de estar y apoyar a las personas y a los equipos, luchando para que la transformación social y humana estuviese siempre presente en la práctica de la solidaridad internacional.'
  },
  subsections: {
    title: "THE WAY WE WORK",
    category: {
      monitoreo: "M&E",
      aprendizaje: "ORGANIZATIONAL LEARNING",
      facilitacion: "FACILITATION & TRAINING",
      asesoramiento: "ASSESSING ORGANIZATIONS",
      diseno: "DESIGN"
    },
    related: "Related projects",
    viewmore: "VIEW ALL"
  },
  projects: {
    all: "All",
    category: {
      monitoreo: "M&E",
      aprendizaje: "ORGANIZATIONAL LEARNING",
      facilitacion: "FACILITATION & TRAINING",
      asesoramiento: "ASSESSING ORGANIZATIONS",
      diseno: "DESIGN"
    },
    noproject: "There are not project for this section",
    button_back: "Back"


  },
  footer: {
    buttonSend: "Send"
  },
  cookies: {
    title: "This website uses cookies",
    text: "This website uses cookies to improve your experience. We'll assume you're ok with , but you can opt-out if you wsih",
    read: "Read more",
    buttonAccept: "Accept",
    buttonDeclied: "Reject"
  }
}

export default en;