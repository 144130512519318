import React, { useState } from 'react';
import { connect } from 'react-redux';
import Scrollchor from 'react-scrollchor';
import { useScrollPosition } from '@n8tb1t/use-scroll-position'
import ImageBgr from '../../../../assets/images/ubora_bkg.jpg';
import localization from "../../../../localization";
import './HeroImage.scss';
import { FaChevronDown } from "react-icons/fa";


const HeroImage = ({ sections }) => {

  const idFirstSection = 'service-section'
  //console.log(idFirstSection)
  const [headerStyle, setHeaderStyle] = useState({
    transition: 'all 200ms ease-in'
  })
  const [textStyle, setTextStyle] = useState({
    transition: 'all 200ms ease-in'
  })



  useScrollPosition(
    ({ prevPos, currPos }) => {
      const isVisible = true
      const fadeUntil = 3;
      let opacity = (-fadeUntil / currPos.y).toFixed(1);
      const shouldBeStyle = {
        opacity: currPos.y === 0 ? 1 : opacity,
        transition: `all 200ms ${isVisible ? 'ease-in' : 'ease-out'}`,
        display: currPos.y >= -355 ? 'block' : 'none'
      }
      if (JSON.stringify(shouldBeStyle) === JSON.stringify(headerStyle)) return

      setHeaderStyle(shouldBeStyle)
    },
    [headerStyle]
  )
  useScrollPosition(
    ({ prevPos, currPos }) => {
      const isVisible = false
      const fadeUntil = 0.01;
      let opacity = (fadeUntil * -currPos.y).toFixed(1);
      const shouldBeStyle = {
        opacity: currPos.y === 0 ? 0 : opacity,
        transition: `all 200ms ${isVisible ? 'ease-in' : 'ease-out'}`,
        display: currPos.y >= -700 ? 'block' : 'none'
      }

      if (JSON.stringify(shouldBeStyle) === JSON.stringify(textStyle)) return

      setTextStyle(shouldBeStyle)
    },
    [textStyle]
  )
  return (
    <div className="home__image--bg" id="home">
      <p className="home__text" dangerouslySetInnerHTML={{ __html: localization.home.text }} style={{ ...textStyle }}></p>
      <img className="home__image__logo" style={{ ...headerStyle }} src={window.location.origin + localization.home.linkImage} alt="logo home" />
      <img className="home__image__bg" src={ImageBgr} alt="Home" />
      <div className="home__icon--arrow" style={{ ...headerStyle }}>
        <Scrollchor to={`#${idFirstSection}`}>
          <FaChevronDown size={'40px'} color={'#ffffff'} />
        </Scrollchor>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return state
}

export default connect(mapStateToProps)(HeroImage)
