import React, { useState, useEffect } from 'react'
import { Parallax } from 'react-parallax';
import './ParalaxImage.scss';

const Paralaximage = (props) => {
  const [text, setText] = useState(props.text)

  useEffect(() => {
    setText(props.text)
  }, [props.text, text])


  return (
    <div className="col-12 p-0">
      <Parallax
        blur={1}
        bgImage={props.image}
        bgImageAlt={props.alt}
        strength={props.height}
      >
        <div className="container d-flex justify-content-center">
          <div style={{ height: props.height }} className="d-flex align-items-center">
            <h3 className='image-paralax__text'>{props.text}</h3>
          </div>
        </div>
      </Parallax>
    </div>
  )
}

export default Paralaximage

