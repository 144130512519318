//import LocalizedStrings from 'react-localization';
import LocalizedStrings from 'localized-strings';
import es from './es/'
import en from './en/'
import ca from './ca/'


let localization = new LocalizedStrings({
  es,
  en,
  ca
});
export default localization; 