const es = {
  home: {
    services: {
      title: 'NUESTROS SERVICIOS',
      testimonial: 'Nos motiva trabajar junto a las personas y a las organizaciones y acompañarlas en sus procesos de construcción y de cambio. Queremos compartir contigo nuestra experiencia y nuestros conocimientos en prácticas organizativas y en metodologías innovadoras de M&E. Te invitamos a conocer nuestra página web y los servicios que ofrecemos.'
    },
    text: "UBORA es un equipo que trabaja para mejorar las prácticas organizativas y la planificación estratégica de las organizaciones, así como el M&E de proyectos y programas que contribuyen al cambio y a la transformación social. <br><br>Trabajamos asesorando, desarrollando capacidades y promoviendo buenas prácticas organizativas.",
    linkImage: '/assets/images/logo_ubora_white_esp.png',
    footer: {
      name: "Nombre *",
      email: "E-mail *",
      boxtext: "Escribe tu mensaje aquí *",
      required: "* Campos son obligatorios.",
      talk: "Hola, vamos a hablar",
      button: "Enviar",
      terms: "Términos Legales",
      policies: "Políticas de Privacidad",
      cookies: "Cookies",
      errorName: 'Se requiere el nombre',
      errorEmail: 'Se requiere correo electrónico y debe ser un correo electrónico válido',
      errorMessage: 'El mensaje es obligatorio y debe tener más de 40 caracteres',
    }
  },
  menu: {
    buttonName: "Nosotros",
    titleMobile: "conócenos",
    what: 'Qué es Ubora?',
    appoarch: 'Nuestro enfoque',
    how: "La forma en que trabajamos",
    team: 'Equipo'
  },
  what: {
    title: "QUÉ ES UBORA?",
    text: "En kiswahili, “ubora” significa “hacer las cosas bien, tener ganas; excelencia” y nos remite a un sistema de aprendizaje y de buenas prácticas organizativas. El término “buenas prácticas” tiene muchos significados, pero se podría definir como una situación en la que todos los elementos de la organización (estrategia, aspectos operacionales, estructura y cultura organizativa) están trabajando de manera eficiente y eficaz para alcanzar progresos medibles y a largo plazo. <br/><br/> UBORA, aprendizaje organizativo y calidad en la gestión, es una organización catalana que ofrece sus servicios principalmente a organizaciones gubernamentales y a entidades sociales de carácter local, regional y estatal, aunque también trabaja en red a escala internacional. Ofrece servicios de asesoramiento que apuestan por la capacitación y por el aprendizaje organizativo, es decir, por generar procesos de fortalecimiento institucional a través de su trabajo (dimensión organizativa y operativa vinculada a la ejecución de proyectos). Integra una diversidad de visiones de diferentes actores, y trabaja complementando sus capacidades con una amplia red de colaboradores internacionales especializados en diversos ámbitos. <br/><br/> En UBORA asesoramos a las organizaciones para materializar sus ideas, para reflexionar sobre cómo se hacen las cosas, cómo se organizan, cómo se mejora la gestión interna, y sobre todo para poder aprender a mejorar sus prácticas organizacionales. <br/><br/> Creemos que los procesos de monitoreo y evaluación son esenciales. Utilizamos métodos innovadores que nos permiten reflexionar sobre el desempeño de nuestras acciones y programas, y mejorar nuestras prácticas para lograr alcances tangibles."
  },
  approach: {
    title: "NUESTRO ENFOQUE",
    quote: "Los argonautas decían:<br/> “Navegar es preciso, vivir no es preciso”.<br/>Quiero para mí el espíritu de esta frase, transformada.<br/>La forma de casarla con lo que yo soy:<br/>vivir no es necesario, lo que es necesario es crear.",
    autor: "FERNANDO PESSOA",
    text: "Creemos, como los argonautas, que “navegar es preciso”. Es preciso para vencer los obstáculos de todos los mares, pero para hacerlo necesitamos navegar con un nuevo mapa que nos guíe. Pensamos, como Fernando Pessoa, que “lo que es necesario es crear”. Aprender nuevas formas de acción y también desaprender las antiguas, entender cuáles pueden ser las nuevas oportunidades que nos permitan continuar trabajando en un sector, la cooperación y la educación para el desarrollo, muy necesario, ahora más que nunca",
    subtitle: "LOS PILARES QUE ORIENTAN NUESTRO TRABAJO SON LOS SIGUIENTES:",
    pillars: {
      first: "Utilizamos y combinamos diferentes miradas metodológicas que se complementan: outcome mapping, teoría del cambio, most significant change, facilitación de grupos basada en trabajo de procesos y reflect-action, entre otras.",
      second: "Facilitamos el intercambio y la generación de conocimiento entre diferentes realidades y prácticas de desarrollo, a través de la creación de canales de comunicación abiertos, participativos y horizontales que permiten que las experiencias, prácticas y conocimientos generados en el Sur se reconozcan.",
      thrid: "Establecemos puentes entre el conocimiento en gestión organizativa y la calidad, con el conocimiento en capacitación organizativa proveniente del ámbito de la cooperación internacional para el desarrollo.",
      fourth: "Trabajamos impulsando la colaboración entre múltiples actores y en contextos complejos (ONGD, Administración pública, iniciativas de emprendeduría social, instituciones educativas, etc.)."
    },
    testtimonial_title: "TESTIMONIOS"

  },
  team: {
    title: 'EL EQUIPO',
    text_one: '&#8226 Contamos con una larga experiencia y una clara proyección internacional. <br>&#8226 Trabajamos desde un conocimiento aplicado, aportando soluciones contextualizadas para cada necesidad. <br>&#8226 Trabajamos en equipo, y en colaboración con una amplia red de organizaciones y profesionales de diversos países con quienes compartimos nuestro enfoque y forma de trabajar, y que cuentan con la experiencia y la especialización necesarias para dar respuesta a las necesidades de cada encargo.',
    text_two: 'UBORA se constituye como espacio interdisciplinar en el que confluyen profesionales de ámbitos diversos con experiencia consolidada en cooperación internacional para el desarrollo. Cuenta con un equipo de personas con experiencia vinculada a la cooperación y a los actores con los que hemos trabajado: Administración pública, ONG, universidades del Norte y del Sur, etc...',
    title_memorial: 'En memoria a Inés Romero',
    text_memorail: 'La recordaremos por su empatía, rigurosidad y pasión profesional, por su meticulosidad, cuidado y vasto conocimiento, por su forma de estar y apoyar a las personas y a los equipos, luchando para que la transformación social y humana estuviese siempre presente en la práctica de la solidaridad internacional.'
  },
  subsections: {
    title: "CÓMO TRABAJAMOS",
    category: {
      monitoreo: "M&E",
      aprendizaje: "APRENDIZAJE ORGANIZACIONAL",
      facilitacion: "FACILITACIÓN Y FORMACIÓN",
      asesoramiento: "ASESORAMIENTO REDES",
      diseno: "DISEÑO"
    },
    related: "Proyectos relacionados",
    viewmore: "VER TODOS"
  },
  projects: {
    all: "Todos",
    category: {
      monitoreo: "M&E",
      aprendizaje: "APRENDIZAJE ORGANIZACIONAL",
      facilitacion: "FACILITACIÓN Y FORMACIÓN",
      asesoramiento: "ASESORAMIENTO REDES",
      diseno: "DISEÑO"
    },
    noproject: "No hay project6os para esta sección",
    button_back: "Atrás"

  },
  footer: {
    buttonSend: "Enviar"
  },
  cookies: {
    title: "Esta web usa cookies",
    text: "Esta web usa cookies para mejorar su experiencia. Asumimos que usted esta deacuerdo, pero usted puede recharzarlo, si quiere",
    read: "Leer más",
    buttonAccept: "Aceptar",
    buttonDeclied: "Rechazar"
  }
}

export default es;