import React from 'react';
import { withRouter } from "react-router";
import { Dropdown, DropdownButton } from 'react-bootstrap'
import { HashLink as Link } from 'react-router-hash-link';
import localization from "../../../localization";
import Logo from '../../../assets/images/ic_logo_ubora_color.png';
import { FaRegEnvelope } from "react-icons/fa";

const HeaderDesktop = ({ history, sections }) => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-3">
          <div className="header__logo">
            <Link smooth to={`/#home`}>
              <img src={Logo} alt="logo" />
            </Link>
          </div>
        </div>
        <div className="col-6">
          <div className="header__icons row d-flex justify-content-center align-items-center">
            {sections && sections.map(section => (
              <div key={section.id} className="col align-items-center">
                <Link smooth to={`/#${section.id}`} ><img src={section.icon_small.url} alt="" /></Link>
              </div>
            ))}
          </div>
        </div>
        <div className="header__menu col-3 d-flex justify-content-end align-items-center">
          <DropdownButton
            className={"button-dropdown"}
            id="dropdown-main-menu"
            title={localization.menu.buttonName}
          >
            <Dropdown.Item href="/what">{localization.menu.what}</Dropdown.Item>
            <Dropdown.Item href="/appoarch">{localization.menu.appoarch}</Dropdown.Item>
            <Dropdown.Item href="/projects">{localization.menu.how}</Dropdown.Item>
            <Dropdown.Item href="/team">{localization.menu.team}</Dropdown.Item>
          </DropdownButton>
          <Link smooth to={`/#footer`} ><FaRegEnvelope size={'22px'} /></Link>

        </div>
      </div>
    </div>
  )
}
export default withRouter(HeaderDesktop)
