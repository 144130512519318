export const GET_EVENT = 'GET_EVENT';
export const ERROR_EVENT = 'ERROR_EVENT';

export const GET_SECTIONS = 'GET_EVENT';
export const GET_SECTIONS_SUCCESS = 'GET_SECTIONS_SUCCESS';
export const GET_SECTIONS_FAILURE = 'GET_SECTIONS_FAILURE';

export const SETTINGS_GETLANGUAGE = 'settings/getLanguage';
export const SETTINGS_SETLANGUAGE = 'settings/setLanguage';

export const UI_IS_OPEN_MENU = 'ui/isOpenMenu';
export const UI_GET_Y_POSITION = 'ui/getYPosition';
export const UI_IS_COOKIES_ACCEPT = 'ui/isCookiesAccept';


export const FILTER_GETFILTER = 'filter/getFilter';