import { combineReducers } from 'redux';
import SettingsReducer from './settings.reducer';
import UxReducers from "./ui.reducers";
import FilterReducer from "./filter.reducer";


export default combineReducers({
  ux: UxReducers,
  settings: SettingsReducer,
  filters: FilterReducer
});