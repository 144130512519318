import React from 'react';
import { connect } from 'react-redux';
import ReactMarkdown from 'react-markdown/with-html';
import { useHistory, Link } from "react-router-dom";
import { useLocation } from "react-router";
import { useQuery } from '@apollo/react-hooks';
import { GET_SUBSECTION } from '../../services/Queries';
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import { Header, Spinner, Footer } from "../../components/index.components";
import WrapperProjects from "../Projects/components/WrapperProjects";
import localization from '../../localization';


import "./SubSection.scss";

const SubSection = ({ asFilterSections, settings }) => {
  let { language } = settings;
  // let { id } = useParams();
  let history = useHistory();
  function useQueryUrl() {
    return new URLSearchParams(useLocation().search);
  }
  let topView = React.createRef();
  if (topView) {
    console.log(topView)
    //topView.current.scrollTo(0, 0);
  }
  let query = useQueryUrl();
  let id = query.get('id')
  const { data, loading, error } = useQuery(GET_SUBSECTION, {
    variables: { id: id }
  });
  if (loading) return <Spinner />;
  if (error) return <p>ERROR</p>;
  const { subsection } = data;
  const textFinal = subsection[`content__${language}`]
  const lastProjects = []; //subsection.projects.slice(0, 3)
  return (
    <>
      <Header sections={asFilterSections} headerStyle={{ top: 0 }} />
      <div className="container-fluid subsection" ref={topView}>
        <div className="subsection__hero row">
          <img src={subsection.hero_image.url} alt={subsection[`title__${language}`]} />
          <div className="subsection__hero__box-text">
            <button className="subsection_button--back" onClick={() => history.goBack()}><FaAngleLeft /><span>{localization.projects.button_back}</span></button>
            <p>{subsection[`title__${language}`]}</p>
          </div>
        </div>
        <div className="subsection__content__container">
          <div className="row">
            <div className="col">
              <ReactMarkdown
                className={'subsection__content'}
                source={textFinal}
                escapeHtml={false}
              />
            </div>
          </div>
          {lastProjects && lastProjects.length > 0 ?

            <div className="row">
              <div className="subsection__releated">
                <div className="subsection__releated__title">
                  <h3>{localization.subsections.related}</h3> <span><Link to="/projects">{localization.subsections.viewmore} <FaAngleRight /></Link></span>
                </div>
                <WrapperProjects projects={lastProjects} />
              </div>
            </div>
            : ''}
        </div>
      </div>
      <Footer />
    </>
  )
}

const mapStateToProps = (state) => {
  return state
}

export default connect(mapStateToProps)(SubSection)
