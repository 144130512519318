import React from 'react'
import './ServicesSection.scss';
import { withRouter } from "react-router";

import { Parallax } from 'react-parallax';
import { CarouselMenu } from "../../../../components/index.components";
import ImageServicesHome from '../../../../assets/images/ubora_inicio_bkg.jpg'

import Scrollchor from 'react-scrollchor';

const ServicesSection = (props) => {
  const { text, sections, history } = props
  return (
    <div className="services-section" id="service-section">
      <Parallax
        blur={0}
        bgImage={ImageServicesHome}
        bgImageAlt={'gente feliz con el brazo en alto'}
        strength={1000}
        className={'services-section__image'}
        bgImageStyle={{ opacity: '.7', backgroundColor: 'black' }}
        bgStyle={{ backgroundColor: 'black' }}
      >
        <div className="container   d-flex justify-content-center">
          <div style={{ height: 'auto' }} className="d-flex align-items-center">
            <h3 className='image-paralax__text'>{text.testimonial}</h3>
          </div>
        </div>
      </Parallax>
      <h3>{text.title}</h3>
      <div className="services-section__contianer">


        <div className="col services-section__mobile">
          <CarouselMenu items={sections} />
        </div>
        {sections.map(section => (

          <div key={section.id} className="col services-section__desktop">
            <Scrollchor beforeAnimate={() => history.push(`/#${section.id}`)} to={`#${section.id}`}>

              <div className="services-section__img--container">
                <img src={section.icon_big.url} alt={section.title} />
                <p>{section.title__en || section.title__es || section.title__ca}</p>
              </div>
            </Scrollchor>
          </div>

        ))}
      </div>
    </div>

  )
}

export default withRouter(ServicesSection)
