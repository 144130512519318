import React from 'react';
import { connect } from 'react-redux';
import './CarouselRef.scss';
import Carousel from 'react-bootstrap/Carousel'

const CarouselRef = ({ items, settings }) => {
  let { language } = settings;
  return (

    <Carousel interval={null}>
      {items.map(item => (

        <Carousel.Item key={item.id}>

          <Carousel.Caption>
            <p className="testimonial__text">“ {item[`text_${language}`]} ”</p>
            <p className="testimonial__author"><a href={item.url} target="_blank" rel="noopener noreferrer">{item.Author}</a></p>
            <p className="testimonial__occupation"><a href={item.url} target="_blank" rel="noopener noreferrer"> {item[`occupation_${language}`]}</a> </p>
          </Carousel.Caption>

        </Carousel.Item>

      ))}
    </Carousel>

  )
}


const mapStateToProps = (state) => {
  return state
}

export default connect(mapStateToProps)(CarouselRef)