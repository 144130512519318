import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import CardProject from './CardProject';
import './WrapperProjects.scss';
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";


import localization from '../../../localization';


let total = 0
let page = 0
const WrapperProjects = ({ projects, filter }) => {
  const [items, setItems] = useState([]);
  const [numItems, setNumber] = useState();
  const onFilterProjects = (projects, filter, page) => {

    let newData;
    if (filter === 'all') {
      newData = projects
    } else {
      let datafiltered = projects.filter(project => project.category === filter);
      newData = datafiltered
    }

    setItems(newData.slice(page, page + 9))
    setNumber(newData.length)

  }
  let onLoadMore = (num) => {
    onFilterProjects(projects, filter, num);
  }

  const handelUp = (num) => {
    total = total + num
    onLoadMore(total)
  }
  useEffect(() => {
    onFilterProjects(projects, filter, page)
  }, [projects, filter])

  if (projects.length === 0) return <p> {localization.projects.noproject} </p>
  return (
    <>
      <div className="subsections__wrapper container">
        <div className="row">
          {items.map(project => (
            <CardProject key={project.id} project={project} />
          ))}
        </div>
      </div>
      <div className="subsections__buttons">

        {(total >= 9) ? <button className="prev" onClick={() => handelUp(-9)}><IoIosArrowBack /> PREV</button> : ''}
        {(numItems > (total + 9)) ? <button className="next" onClick={() => handelUp(9)}>NEXT <IoIosArrowForward size="18" /></button> : ''}
      </div>
    </>
  )
}

const mapStateToProps = (state) => ({
  filter: state.filters.filter
})



export default connect(mapStateToProps)(WrapperProjects) 
