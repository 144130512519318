
export const filteredSections = (sections, language) => {
  return sections.map(section => {
    Object.filter = (obj, predicate) =>
      Object.keys(obj)
        .filter(key => predicate(key))
        .reduce((res, key) => ((res[key] = obj[key], res)), {})
    const objectFiltered = Object.filter(section, key => key.slice(-2).includes(language));
    objectFiltered.image = section.image
    objectFiltered.icon_big = section.icon_big
    objectFiltered.icon_small = section.icon_small
    objectFiltered.subsections = section.subsections
    objectFiltered.name_id = section.name_id
    objectFiltered.id = section.id
    return objectFiltered;
  })
}

export const filteredTestimonials = (testimonials, language) => {
  return testimonials.map(testimonial => {
    Object.filter = (obj, predicate) =>
      Object.keys(obj)
        .filter(key => predicate(key))
        .reduce((res, key) => ((res[key] = obj[key], res)), {})
    const objectFiltered = Object.filter(testimonial, key => key.slice(-2).includes(language));
    objectFiltered.url = testimonial.url
    objectFiltered.id = testimonial.id
    objectFiltered.author = testimonial.Author

    return objectFiltered;
  })
}

export const filteredTeam = (teams, language) => {
  return teams.map(team => {
    Object.filter = (obj, predicate) =>
      Object.keys(obj)
        .filter(key => predicate(key))
        .reduce((res, key) => ((res[key] = obj[key], res)), {})
    const objectFiltered = Object.filter(team, key => key.slice(-2).includes(language));
    objectFiltered.picture = team.picture
    objectFiltered.id = team.id
    objectFiltered.name = team.name
    return objectFiltered;
  })
}

