import { FILTER_GETFILTER } from "../actions/types";

const InitialFilterState = {
  filter: 'all'
};

export default function FilterReducer(state = InitialFilterState, action) {
  switch (action.type) {
    case FILTER_GETFILTER: {
      return {
        ...state,
        filter: action.payload.filter
      };
    }
    default:

      return state;
  }
}