import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
// import { createUploadLink } from "apollo-upload-client";
import ApolloClient from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { HttpLink } from 'apollo-link-http';
import { ApolloProvider } from '@apollo/react-hooks';
import { Provider } from 'react-redux';
import AppRouter from './router/AppRouter';
import store from './store';

let linkApi;
if (process.env.NODE_ENV === 'production') {
 linkApi = "https://ubora-services-cms-ea20d10e78c6.herokuapp.com/graphql";
} else {
  linkApi = "http://localhost:1337/graphql";
}

const link = new HttpLink(
  { uri: linkApi }
);

export const client = new ApolloClient({
  link: link,
  cache: new InMemoryCache({
    addTypename: true
  }),

  onError: ({ networkError, graphQLErrors }) => {
    console.log("graphql", graphQLErrors);
    console.log("networkgraphql", networkError);
  }
});



ReactDOM.render(
  <ApolloProvider client={client}>
    <Provider store={store}>

      <AppRouter />

    </Provider>
  </ApolloProvider>
  , document.getElementById('root'));

