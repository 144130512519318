import React from 'react'
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import './CardProject.scss'
import localization from '../../../localization';
import No_image from '../../../assets/images/ubora_bkg.jpg'
const CardProject = ({ project, settings }) => {
  let { language } = settings;

  let src_image = No_image
  if (project.thumbnail) {
    src_image = project.thumbnail.url
  }
  return (
    <div className="col-12 col-sm-6 col-md-6 col-lg-4 card-project">
      <Link to={`/projects/${project.id}`}>

        <div className="img-responsive img-thumbnail ratio-4-3" style={{ backgroundImage: `url(${src_image})` }} ></div>
        <div className="card-project__title__container">
          <p className="card-project__title__text">
            {localization.projects.category[project.category]}

          </p>
          <p>{project[`title__${language}`]}</p>
        </div>
      </Link>
    </div>
  )
}
const mapStateToProps = (state) => {
  return state
}


export default connect(mapStateToProps)(CardProject)

