import React from 'react';
import { Link } from 'react-router-dom';
import './Section.scss';
import { ParalaxImage, Card, CarouselSectionHome } from "../../../../components/index.components";

const Section = ({ info }) => {
  return (
    <div className="section__container" id={`${info.id}`}>
      <ParalaxImage
        image={info.image.url}
        alt={'gente'}
        text={info.testimonial__en || info.testimonial__es || info.testimonial__ca}
        height={475}
      />
      <div className="section__container--text col-12">
        <h3>{info.title__es || info.title__en || info.title__ca}</h3>
        <p className="section__text">
          {info.content__es || info.content__en || info.content__ca}
        </p>
      </div>
      <div className="section__cards">
        {info.subsections.filter(subsection => subsection.atHome === true).map((subsection) => {

          const urlTitle = subsection.title__en.replace(',', '').split(' ').join('-');
          return <div key={subsection.id} className="col-12 col-md-3 col-lg-3">
            <Link to={`subsection/${urlTitle}?id=${subsection.id}`}>
              <Card subsection={subsection} />
            </Link>
          </div>
        })}
      </div>
      {info.subsections.length > 0 ? <div className="section_cards--mobile">
        <CarouselSectionHome items={info.subsections} />
      </div> : ''
      }

    </div>
  )
}

export default Section
