import React from 'react'
import './Spinner.scss'

const Spinner = () => {
  return (
    <div className="spinner__container">
      <div className="lds-dual-ring"></div>
    </div>
  )
}

export default Spinner
