import React from 'react';
import './Header.scss';
import HeaderDesktop from "./components/HeaderDesktop";
import HeaderMobile from "./components/HeaderMobile";



const Header = ({ sections, headerStyle, children }) => {
  return (
    <>
      <div className="container-fluid header--desktop" style={{ ...headerStyle }}>
        <HeaderDesktop sections={sections}>{children}</HeaderDesktop>
      </div>
      <div className="container-fluid header--mobile" style={{ ...headerStyle }}>
        <HeaderMobile sections={sections} />
      </div>
    </>
  )
}

export default Header;
