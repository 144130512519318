import React from 'react';
import { connect } from 'react-redux';
import ReactMarkdown from 'react-markdown/with-html';
import { useHistory } from "react-router-dom";
import { useParams } from "react-router";
import { useQuery } from '@apollo/react-hooks';
import { GET_PROJECT } from '../../services/Queries';
import { FaAngleLeft } from "react-icons/fa";
import localization from '../../localization';

import './Project.scss';
import { Header, Spinner, Footer } from "../../components/index.components";
import No_image from '../../assets/images/ubora_bkg.jpg'

const Project = ({ asFilterSections, settings }) => {
  let { language } = settings;
  let { id } = useParams();
  let history = useHistory();
  const { data, loading, error } = useQuery(GET_PROJECT, {
    variables: { id: id }
  });
  if (loading) return <Spinner />;
  if (error) return <p>ERROR</p>;
  const { project } = data;

  const textFinal = project[`content__${language}`]
  let src_image = No_image
  if (project.hero_image) {
    src_image = project.hero_image.url
  }
  return (
    <>
      <Header sections={asFilterSections} headerStyle={{ top: 0 }} />
      <div className="container-fluid project">
        <div className="project__hero row">
          <img src={src_image} alt={project[`title__${language}`]} />
          <div className="project__hero__box-text">
            <button className="project_button--back" onClick={() => history.goBack()}><FaAngleLeft /></button>
            <p>{project[`title__${language}`]}</p>
          </div>
        </div>
        <div className="project__content__container">
          <div className="row">
            <div className="col">
              <ReactMarkdown
                className={'project__content'}
                source={textFinal}
                escapeHtml={false}
              />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}
const mapStateToProps = (state) => {
  return state
}

export default connect(mapStateToProps)(Project)
