import React from 'react'
import { connect } from 'react-redux'
import { Header, Footer } from "../../components/index.components";

import ImageWhat from '../../assets/images/ubora_que_es_bkg.jpg';
import './What.scss'
import localization from '../../localization';

const What = ({ asFilterSections }) => {
  return (
    <>
      <Header sections={asFilterSections} headerStyle={{ top: 0 }} />
      <div className="what__container container-fluid">
        <div className="row h-100">
          <div className="what__image__contianer">
            <img className="what__image" src={ImageWhat} alt="what Is ubora" />
          </div>
          <div className="col-12">
            <div className="what__text__container">
              <h1 className="what__title">{localization.what.title}</h1>
              <p className="what__text" dangerouslySetInnerHTML={{ __html: localization.what.text }}></p>
            </div>

          </div>

        </div>
      </div>
      <Footer />
    </>
  )
}

export default connect()(What)
