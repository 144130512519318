import React from 'react'
import { connect } from "react-redux";
import { Header, Testimonial, Footer } from "../../components/index.components";
import localization from "../../localization";
import './Approach.scss'
const Approach = ({ asFilterSections }) => {
  return (
    <>
      <Header sections={asFilterSections} headerStyle={{ top: 0 }} />
      <div className="container-fluid">
        <div className="approach__container">
          <div className="approach__section">
            <h3>{localization.approach.title}</h3>
            <div className="approach__section__text">
              <p className="approach__section__quote" dangerouslySetInnerHTML={{ __html: localization.approach.quote }}></p>
              <p className="approach__section__quote">FERNANDO PESSOA</p>
              <p className="approach__section__text--approach">{localization.approach.text}</p>
            </div>
            <div className="approach__section__foundation row">
              <div className="container">
                <h2>{localization.approach.subtitle}</h2>

                <div className="row">

                  <div className="col-12 col-md-3">
                    <p>{localization.approach.pillars.first}</p>
                  </div>
                  <div className="col-12 col-md-3">
                    <p>{localization.approach.pillars.second}</p>
                  </div>
                  <div className="col-12 col-md-3">
                    <p>{localization.approach.pillars.thrid}</p>
                  </div>
                  <div className="col-12 col-md-3">
                    <p>{localization.approach.pillars.fourth}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="approach__testimonial">
            <Testimonial text={localization.approach.testtimonial_title} />
          </div>
        </div>

      </div>
      <Footer />
    </>
  )
}

export default connect()(Approach)
