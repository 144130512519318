import React from 'react'
import { connect } from 'react-redux';
import { GET_TEAM } from '../../../services/Queries';
import { useQuery } from '@apollo/react-hooks';
import { Spinner } from '../../../components/index.components';
import { filteredTeam } from "../../../utils/getAllSections";
import CardTeam from './CardTeam'

const WrapperTeam = ({ settings }) => {
  let { language } = settings;
  const { data, loading, error } = useQuery(GET_TEAM);
  if (loading) return <Spinner />;
  if (error) return <p>ERROR</p>;
  const { teams } = data
  const asFilterTeam = filteredTeam(teams, language);
  return (
    <div className="row">
      {asFilterTeam.map(team => (
        <div className="col-md-6 col-12" key={team.id}>
          <CardTeam team={team} />
        </div>
      ))}
    </div>
  )
}
const mapStateToProps = (state) => {
  return state
}


export default connect(mapStateToProps)(WrapperTeam)
