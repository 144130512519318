import React from 'react';
import { connect } from 'react-redux';
import { Parallax } from 'react-parallax';
import ImageFooter from '../../../../assets/images/ubora_contacto_bkg.jpg';
import localization from "../../../../localization";
import { useForm } from 'react-hook-form'

import './Footer.scss'
import axios from 'axios';
import Footer from '../../../../components/Footer/Footer';

const API_PATH = './api/index.php';

const FooterHome = () => {
  var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const { register, errors, handleSubmit, formState } = useForm({
    mode: "onSubmit"
  });
  const onSubmit = data => {

    if (formState.isValid) {
      axios({
        method: 'post',
        url: `${API_PATH}`,
        headers: { 'content-type': 'application/json' },
        data: data
      }).then(result => {
        console.log("Sent email")
      }).catch(error => alert(error));

    }

  }




  return (
    <div id="footer">
      <Parallax
        blur={1}
        bgImage={ImageFooter}
        bgImageAlt='Send Email'
        strength={500}
      >
        <div className="container d-flex justify-content-center">
          <div style={{ height: 'calc(100vh - 72px' }} className="d-flex align-items-center">
            <div className="footer__container">
              <div className="footer__form--continer">
                <h3 className="footer__title">{localization.home.footer.talk}</h3>
                <div className="row">
                  <div className="col-12 footer__col--form" >
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="footer__from--name">
                        <input
                          id="name"
                          type="text"
                          placeholder={localization.home.footer.name}
                          name="name"
                          ref={register({ required: true })}
                        />
                        <input
                          id="email"
                          type="text"
                          name="email"
                          ref={register({ required: true, pattern: re })}
                          placeholder={localization.home.footer.email}
                        />
                      </div>
                      <div className="footer__form--error">

                        <span>{errors.name && localization.home.footer.errorName}</span>
                        <span>{errors.email && localization.home.footer.errorEmail}</span>
                      </div>
                      <textarea
                        id="message"
                        name="message"
                        ref={register({ required: true, minLength: 40 })}
                        placeholder={localization.home.footer.boxtext}
                      />
                      <div className="footer__form--error">

                        {errors.message && localization.home.footer.errorMessage}
                      </div>
                      <div className="footer__form--required">
                        <span>{localization.home.footer.required}</span>
                      </div>
                      <button className="button" type="submit">{localization.home.footer.button}</button>
                    </form>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </Parallax>
      <Footer />
    </div>
  )
}



export default connect()(FooterHome)
