import React from 'react';
import { connect } from 'react-redux';
import { setLanguage } from '../../services/actions/language.actions';
import './languages.scss';

const Languages = (props) => {

  const language = props.settings.language;
  const onLanguageButtonPress = (lng) => {
    props.dispatch(setLanguage(lng))

  }

  const isActiveHandle = (lng) => (lng === language) ? true : false

  return (
    <div className="language__contianer">
      <ul>
        <li className={(isActiveHandle('en')) ? 'active' : ''} onClick={() => onLanguageButtonPress('en')}>ENG</li>
        <li >|</li>
        <li className={(isActiveHandle('es')) ? 'active' : ''} onClick={() => onLanguageButtonPress('es')}>ESP</li>
        <li >|</li>
        <li className={(isActiveHandle('ca')) ? 'active' : ''} onClick={() => onLanguageButtonPress('ca')}>CAT</li>
      </ul>
    </div>
  )

}

const mapStateToProps = (state) => {
  return state
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Languages);
