const ca = {
  home: {
    services: {
      title: 'ELS NOSTRES SERVEIS',
      testimonial: 'Ens motiva treballar conjuntament amb les persones i les organitzacions, acompanyant-les en els seus processos de construcció i de canvi. Volem compartir amb tu la nostra experiència i els nostres coneixements. Et convidem a conèixer la nostra pàgina web i els serveis que oferim.'
    },
    text: "UBORA és un equip que treballa per a la millora de les pràctiques organitzatives i per a la planificació estratègica de les organitzacions,així com el seguiment i l’avaluació de subsectiones i programes que contribueixen al canvi i a la transformació social. <br><br>Treballem assessorant, desenvolupant capacitats i promovent bones pràctiques organitzatives.",
    linkImage: '/assets/images/logo_ubora_white_cat.png',
    footer: {
      name: "Nom *",
      email: "E-mail *",
      boxtext: "Escriu el teu missage aquí *",
      required: "* Camps són obligatoris.",
      talk: "Hola, parlarem",
      button: "Enviar",
      terms: "Termes Legals",
      policies: "Polítiques de Privacitat",
      cookies: "Cookies",
      errorName: 'El seu nom és obligatori',
      errorEmail: 'El correu electrònic és obligatori i ha de ser un correu electrònic vàlid',
      errorMessage: 'El missatge és obligatori i ha de ser superior a 40 caràcters',

    }
  },
  menu: {
    buttonName: "Nosaltres",
    titleMobile: "Coneix-nos",
    what: 'Què és Ubora?',
    appoarch: 'La nostra mirada',
    how: "La nostra manera de treballar",
    team: 'Equip'
  },
  what: {
    title: "QUÉ ES UBORA?",
    text: "En kiswahili, 'ubora' significa 'fer les coses bé, amb ganes; excel·lència 'i ens remet a un sistema d'aprenentatge i de bones pràctiques organitzatives. <br/> <br/> El terme 'bones pràctiques' té molts significats, però es podria definir com una situació en la qual tots els elements de l'organització (estratègia, aspectes operacionals, estructura i cultura organitzativa) estan treballant de manera eficient i eficaç per assolir progressos mesurables I a llarg termini.<br/> <br/>UBORA, (Assessorament i desenvolupament de capacitats orientades a la transformació social), és una organització catalana que ofereix els seus serveis principalment a organitzacions governamentals i entitats socials de caràcter local, regional i estatal, tot i que també treballa en xarxa a escala internacional. Ofereix serveis d'assessorament que aposten per la capacitació i per l'aprenentatge organitzatiu, és a dir, per generar processos d'enfortiment institucional a través del seu treball (dimensió organitzativa i operativa vinculada a l'execució de subsectiones). Integra una diversitat de visions de diferents actors, i treballa complementant les seves capacitats amb una àmplia xarxa de col·laboradors internacionals especialitzats en diversos àmbits.<br/> <br/> A UBORA assessorem a les organitzacions perquè puguin materialitzar les seves idees, reflexionar sobre com es fan les coses, com s'organitzen, com es millora la gestió interna, i sobretot per poder aprendre a millorar les seves pràctiques organitzacionals.<br/> <br/>Creiem que els processos de seguiment i avaluació són essencials. Utilitzem mètodes innovadors que ens permeten reflexionar sobre l'acompliment de les nostres accions i programes, i millorar les nostres pràctiques per a aconseguir canvis tangibles."
  },
  approach: {
    title: "LA NOSTRA MIRADA",
    quote: "Els argonautes deien:<br/> &quot;Navegar és necessari, viure no és necessari&quot;.<br/>Vull per a mi l'esperit d'aquesta frase, transformada.<br/>La forma de casar amb el que jo sóc:<br/>viure no és necessari, el que cal és crear.",
    autor: "FERNANDO PESSOA",
    text: "Creiem, com els argonautes, que &quot;navegar és necessari&quot;. I cal navegar per tal de vèncer els obstacles de tots els mars, però per fer-ho necessitem navegar amb un nou mapa que ens guiï. Pensem, com Fernando Pessoa, que &quot;el que cal és crear&quot;. Aprendre noves formes d'acció i també desaprendre les antigues, entendre quines poden ser les noves oportunitats que ens permetin continuar treballant en l’àmbit de la cooperació i l’educació per al desenvolupament, es revelen, ara més que mai, com a necessàries.",
    subtitle: "Els pilars que orienten el nostre treball són els següents:",
    pillars: {
      first: "Utilitzem i combinem diferents mirades metodològiques que es complementen: outcome mapping, teoria del canvi, el canvi més significatiu, facilitació de grups basada en treball de processos I democràcia profunda, i reflect-acció, entre d'altres.",
      second: "Facilitem l'intercanvi i la generació de coneixement entre diferents realitats i pràctiques de desenvolupament, a través de la creació de canals de comunicació oberts, participatius i horitzontals que permeten que les experiències, pràctiques i coneixements generats al Sud es reconeguin.",
      thrid: "Establim ponts entre el coneixement en gestió i qualitat organitzativa, amb el coneixement en capacitació organitzativa provinent de l'àmbit de la cooperació internacional per al desenvolupament.",
      fourth: "Treballem impulsant la col·laboració entre múltiples actors i en contextos complexos (ONGD, Administració pública, iniciatives d'emprenedoria social, institucions educatives, etc.)."
    },
    testtimonial_title: "TESTIMONIS"

  },
  team: {
    title: "EQUIP",
    text_one: "&#8226 Comptem amb una llarga experiència i una clara projecció internacional.<br>&#8226 Treballem des d'un coneixement aplicat, aportant solucions contextualitzades per a cada necessitat. <br>&#8226 Treballem en equip, i en col·laboració amb una àmplia xarxa d'organitzacions i professionals de diversos països amb els quals compartim el nostre enfocament i forma de treballar, i que compten amb l'experiència i l'especialització necessàries per donar resposta a les necessitats de cada encàrrec.",
    text_two: "UBORA es constitueix com un espai interdisciplinari on hi conflueixen professionals d'àmbits diversos amb experiència consolidada en cooperació internacional per al desenvolupament. Compta amb un equip de persones amb experiència assessorant a  diferents actors: Administració pública, ONG, universitats del Nord i del Sud, etc.",
    title_memorial: 'En memoria a Inés Romero',
    text_memorail: 'La recordaremos por su empatía, rigurosidad y pasión profesional, por su meticulosidad, cuidado y vasto conocimiento, por su forma de estar y apoyar a las personas y a los equipos, luchando para que la transformación social y humana estuviese siempre presente en la práctica de la solidaridad internacional.'
  },
  subsections: {
    title: "CÓM TRABALLEM",
    category: {
      monitoreo: "M&E",
      aprendizaje: "APRENENTATGE ORGANITZACIONAL",
      facilitacion: "FORMACIÓ I FACILITACIÓ",
      asesoramiento: "ASSESSORAMENT XARXES",
      diseno: "DISENY"
    },
    related: "Projectes relacionats",
    viewmore: "VEURE TOTS"
  },
  projects: {
    all: "Tots",
    category: {
      monitoreo: "M&E",
      aprendizaje: "APRENENTATGE ORGANITZACIONAL",
      facilitacion: "FORMACIÓ I FACILITACIÓ",
      asesoramiento: "ASSESSORAMENT XARXES",
      diseno: "DISENY"
    },
    noproject: "No ni ha projectes per aquesta secciò",
    button_back: "Endarrere"
  },
  footer: {
    buttonSend: "Enviar"
  },
  cookies: {
    title: "Aquest web fa servir galetes",
    text: "Aquest web fa servir galetes per millorar la seva experiència. Assumim que vostè aquesta deacuerdo, però vostè pot recharzarlo, si vol",
    read: "Llegir més",
    buttonAccept: "Acceptar",
    buttonDeclied: "Rebutjar"
  }
}

export default ca;