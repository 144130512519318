import React from 'react'; import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import './Footer.scss';
import localization from "../../localization";

const Footer = () => {
  return (
    <div className="footer__footer">
      <div className="container">
        <span><a rel="license" href="http://creativecommons.org/licenses/by/4.0/"><img alt="Creative Commons License" src="https://i.creativecommons.org/l/by/4.0/80x15.png" /></a><br />This work is licensed under a <a rel="license" href="http://creativecommons.org/licenses/by/4.0/">Creative Commons Attribution 4.0 International License</a> | www.ubora.services</span>
        <div className="footer__footer__politicas">
          <span><Link to="/terms">{localization.home.footer.terms}</Link></span> | <span><Link to="/cookies">{localization.home.footer.cookies}</Link></span>
        </div>
      </div>
    </div>
  )
}

export default connect()(Footer)
