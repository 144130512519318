import React from 'react';
import { connect } from 'react-redux';
import { createBrowserHistory } from 'history';
import { Router, Route, Switch } from 'react-router';
import { Home, What, Team, NotFound, Approach, Projects, Project, SubSection, LegalText } from "../layouts/index.layout";
import { SideMenu } from '../components/index.components'
import { Spinner } from '../components/index.components';
import { Container, Row } from 'react-bootstrap'
import { GET_SECTIONS } from '../services/Queries';
import { useQuery } from '@apollo/react-hooks';
import { filteredSections } from "../utils/getAllSections";

const history = createBrowserHistory({
  basename: process.env.PUBLIC_URL
});



const AppRouter = ({ settings }) => {

  let { language } = settings;
  const { data, loading, error } = useQuery(GET_SECTIONS);

  if (loading) return <Spinner />;
  if (error) return <p>ERROR</p>;

  const { sections } = data;
  const asFilterSections = filteredSections(sections, language);
  return (
    <Router history={history}>
      <Container fluid={true}>
        <Row>
          <Switch>
            <Route exact path="/" component={() => <Home asFilterSections={asFilterSections} />} />
            <Route exact path="/what" component={() => <What asFilterSections={asFilterSections} />} />
            <Route exact path="/appoarch" component={() => <Approach asFilterSections={asFilterSections} />} />
            <Route exact path="/projects" component={() => <Projects asFilterSections={asFilterSections} />} />
            <Route exact path="/projects/:id" component={() => <Project asFilterSections={asFilterSections} />} />
            <Route exact path="/subsection/:title__en" component={() => <SubSection asFilterSections={asFilterSections} />} />
            <Route exact path="/team" component={() => <Team asFilterSections={asFilterSections} />} />
            <Route exact path="/cookies" component={() => <LegalText asFilterSections={asFilterSections} />} />
            <Route exact path="/terms" component={() => <LegalText asFilterSections={asFilterSections} />} />
            <Route exact path="/policies" component={() => <LegalText asFilterSections={asFilterSections} />} />
            <Route component={() => <NotFound asFilterSections={asFilterSections} />} />
          </Switch>
          <SideMenu asFilterSections={asFilterSections} />
        </Row>
      </Container>
    </Router>
  )
}
const mapStateToProps = (state) => {
  return state
}

export default connect(mapStateToProps)(AppRouter);