import React from 'react';
import { connect } from 'react-redux'
import { Header, Footer } from "../../components/index.components";
import localization from "../../localization";
import WrapperTeam from "./components/WrapperTeam";
import './Team.scss';
import Memorial from './components/Memorial';
const Team = ({ asFilterSections }) => {
  return (
    <>
      <Header sections={asFilterSections} headerStyle={{ top: 0 }} />
      <div className="container-fluid">
        <div className="team__container">
          <div className="team__section">
            <h3>{localization.team.title}</h3>
            <div className="team__section__text">
              <p dangerouslySetInnerHTML={{ __html: localization.team.text_one }}></p>
              <p>{localization.team.text_two}</p>
            </div>
          </div>
          <div className="team_cards">
            <WrapperTeam />
          </div>
          <Memorial title={localization.team.title_memorial} text={localization.team.text_memorail} />
        </div>

      </div>
      <Footer />
    </>
  )
}

export default connect()(Team)
