import React from 'react';
import { connect } from "react-redux";
import './Filters.scss';
import { setFilter } from "../../services/actions/filter.actions";
import localization from "../../localization";
const Filters = ({ sections, settings, filters, onFilter }) => {
  let { language } = settings;
  const { filter } = filters
  const onFilterButtonPress = (filter) => {
    if (filter === 'all') {
      onFilter(filter)
    } else {

      onFilter(filter)

    }
  }

  return (
    <div className="filters">
      <ul>
        <li
          className={filter === 'all' ? 'active' : ''}
          onClick={() => onFilterButtonPress('all')}>{localization.getString("projects.all")}</li>
        {sections.map(section => (
          <li
            key={section.id}
            className={filter === section.name_id ? 'active' : ''}
            onClick={() => onFilterButtonPress(section.name_id)}>
            {section[`title__${language}`]}
          </li>))}
      </ul>
    </div>
  )
}
const mapStateToProps = (state) => {
  return state
}

const mapDispatchToProps = dispatch => ({
  onFilter: params => dispatch(setFilter(params))
})
export default connect(mapStateToProps, mapDispatchToProps)(Filters)
