import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Card from "../Card/Card";

import './CarouselSectionHome.scss';
import Carousel from 'react-bootstrap/Carousel'

const CarouselSectionHome = ({ items }) => {

  return (

    <Carousel interval={null}>
      {items.map(subsection => {
        const urlTitle = subsection.title__en.replace(',', '').split(' ').join('-');
        return (
          <Carousel.Item key={subsection.id}>
            <Link to={`subsection/${urlTitle}?id=${subsection.id}`}>
              <Card subsection={subsection} />
            </Link>
          </Carousel.Item>)

      })}
    </Carousel>

  )
}


const mapStateToProps = (state) => {
  return state
}

export default connect(mapStateToProps)(CarouselSectionHome)