/* eslint-disable no-case-declarations */
/* global localStorage */
/* eslint no-undef: "error" */

import {
  UI_IS_OPEN_MENU,
  UI_GET_Y_POSITION,
  UI_IS_COOKIES_ACCEPT
} from '../actions/types';
const InitialLanguageState = {
  isOpen: false,
  yPosition: 0,
  isCookiesAccept: false
};

export default function UxReducers(state = InitialLanguageState, action) {
  switch (action.type) {
    case UI_IS_OPEN_MENU: {
      return {
        ...state,
        isOpen: !state.isOpen
      };
    }
    case UI_GET_Y_POSITION: {
      return {
        ...state,
        yPosition: action.payload
      };
    }
    case UI_IS_COOKIES_ACCEPT: {
      localStorage.setItem('cookies', true);
      return {
        ...state,
        isCookiesAccept: true
      };
    }
    default:
      const cookies = localStorage.getItem('cookies') || false
      return {
        ...state,
        isCookiesAccept: cookies
      };
  }
}