import React from 'react'
import './CardTeam.scss'
const CardTeam = ({ team }) => {
  return (
    <div className="card-team__container">
      <div className="img-responsive img-thumbnail ratio-4-3" style={{ backgroundImage: `url(${team.picture.url})` }} ></div>
      <p className="card-team__name">{team.name}</p>
      <p className="card-team__text">{team.profile_ca || team.profile_en || team.profile_es}</p>
    </div>
  )
}

export default CardTeam
