import React from 'react'
import { useQuery } from '@apollo/react-hooks';
import { connect } from "react-redux"
import localization from '../../localization';
import WrapperProjects from './components/WrapperProjects';
import { GET_PROJECTS } from '../../services/Queries';
import { Header, Spinner, Filters, Testimonial, Footer } from "../../components/index.components";
import './Projects.scss';



const Subsections = ({ asFilterSections }) => {

  const { data, loading, error } = useQuery(GET_PROJECTS);
  if (loading) return <Spinner />;
  if (error) return <p>ERROR</p>;
  const { projects } = data;

  return (
    <>
      <Header sections={asFilterSections} headerStyle={{ top: 0 }} />
      <div className="container-fluid">
        <div className="subsections__container">
          <div className="subsections__section">
            <h3>{localization.subsections.title}</h3>
            <Filters sections={asFilterSections} />
            <WrapperProjects
              projects={projects}
            />
          </div>
          <Testimonial text={localization.approach.testtimonial_title} />
        </div>

      </div>
      <Footer />
    </>
  )
}

export default connect()(Subsections)
