import React from 'react'
import { connect } from 'react-redux';
import { GET_TESTIMONIALS } from '../../services/Queries';
import { useQuery } from '@apollo/react-hooks';
import { Spinner, CarouselRef } from '../index.components'; import './Testimonial.scss';

const Testimonial = ({ text }) => {

  const { data, loading, error } = useQuery(GET_TESTIMONIALS);
  if (loading) return <Spinner />;
  if (error) return <p>ERROR</p>;
  const { testimonials } = data
  return (
    <div className="testimonial__container container">
      <h3>{text}</h3>
      <div className="row">

        <div className="testimonial__content col-12">
          <CarouselRef items={testimonials} />
        </div>

      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return state
}

export default connect(mapStateToProps)(Testimonial)
