import React from 'react'
import { connect } from 'react-redux'
import './Card.scss'
const Card = ({ subsection, settings }) => {
  let { language } = settings;
  return (
    <div className="card">

      <div className="card__image">
        <img src={subsection.thumbnail && subsection.thumbnail.url} alt="" />
      </div>
      <h3 className="card__text">
        {subsection[`title__${language}`]}
      </h3>

    </div>
  )
}

const mapStateToProps = (state) => {
  return state
}


export default connect(mapStateToProps)(Card)
