import React from 'react';
import { connect } from 'react-redux';
import ReactMarkdown from 'react-markdown/with-html';
import { useQuery } from '@apollo/react-hooks';
import { Header, Spinner, Footer } from "../../components/index.components";
import { useLocation } from "react-router";
import { gql } from 'apollo-boost';
import './Legaltext.scss'


const LegalText = ({ asFilterSections, settings }) => {
  let { language } = settings;
  let location = useLocation();
  const param = location.pathname.slice(1)
  const GET_LEGALTEXT = gql`
    query getlegaltexts($id_name: String!){
      legaltexts(where:{id_name: $id_name}){
        id
        id_name
        title__en
        title__ca
        content__en
        content__es
        content__ca
        createdAt
      }
    }
  `
  const { data, loading, error } = useQuery(GET_LEGALTEXT, {variables: { id_name: param }});
  if (loading) return <Spinner />;
  if (error) return <p>ERROR</p>;
  const content = data.legaltexts[0];
  const textFinal = content[`content__${language}`];

  return (
    <>
      <Header sections={asFilterSections} headerStyle={{ top: 0 }} />

      <div className="container legaltest">
        <div className="row">
          <div className="col">
            <h3 className="legaltest__title">{content[`title__${language}`]}</h3>
            <ReactMarkdown
              className={'legaltest__content'}
              source={textFinal}
              escapeHtml={false}
            />
          </div>
        </div>
      </div>
      <Footer />

    </>
  )
}
const mapStateToProps = (state) => {
  return state
}

export default connect(mapStateToProps)(LegalText);



