import React from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { FaBars } from "react-icons/fa";
import { HashLink as Link } from 'react-router-hash-link';
import { FaRegEnvelope } from "react-icons/fa";
import { ToogleMenu, GetYPosition } from '../../../services/actions/ui.actions';
import Logo from '../../../assets/images/ic_logo_ubora_color.png';

const HeaderMobile = ({ history, dispatch }) => {

  const onOpenMenuButtonPress = e => {
    e.preventDefault()
    dispatch(GetYPosition(window.pageYOffset))
    dispatch(ToogleMenu())
  }
  return (
    <div className="row d-flex">
      <div className="col header--mobile__icon-menu">
        <button onClick={(e) => onOpenMenuButtonPress(e)}><FaBars size={"32px"} /></button>
      </div>
      <div className="col">
        <div className="header--mobile__logo">
          <Link to="/">
            <img src={Logo} alt="logo" />
          </Link>
        </div>
      </div>
      <div className="col header--mobile__email">
        <Link smooth to={`/#footer`} ><FaRegEnvelope size={'22px'} /></Link>
      </div>
    </div>
  )
}


const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  }
}

export default withRouter(connect(null, mapDispatchToProps)(HeaderMobile))
