import {
  UI_IS_OPEN_MENU,
  UI_GET_Y_POSITION,
  UI_IS_COOKIES_ACCEPT
} from './types';



export function ToogleMenu() {
  return {
    type: UI_IS_OPEN_MENU
  }
}

export function GetYPosition(yposition) {
  return {
    payload: yposition,
    type: UI_GET_Y_POSITION
  }
}

export function AcceptCookies() {
  return {
    payload: true,
    type: UI_IS_COOKIES_ACCEPT
  }
}


