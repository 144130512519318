import React, { useRef, useState } from 'react';
import { connect } from "react-redux";
import './Home.scss';
import { useScrollPosition } from '@n8tb1t/use-scroll-position'
import { Languages, Header, Cookies } from "../../components/index.components";
import { HeroImage, Section, ServicesSection, FooterHome } from './components'
import localization from "../../localization";

const Home = ({ asFilterSections }) => {

  const [headerStyle, setHeaderStyle] = useState({
    transition: 'all 200ms ease-in'
  })
  const sectionRef = useRef()

  useScrollPosition(
    ({ prevPos, currPos }) => {
      const shouldBeStyle = {
        top: currPos.y > 0 ? '-76px' : '0',
        transition: `all 200ms ${currPos.y > 0 ? 'ease-in' : 'ease-out'}`,
      }
      if (JSON.stringify(shouldBeStyle) === JSON.stringify(headerStyle)) return
      setHeaderStyle(shouldBeStyle)
    }, [], sectionRef
  )

  return (
    <div className="home__container">
      <Header sections={asFilterSections} headerStyle={{ ...headerStyle }} />
      <Languages />
      <HeroImage sections={asFilterSections} />
      <ServicesSection text={localization.home.services} sections={asFilterSections} />
      <div ref={sectionRef}>
        {asFilterSections.map(section => (<Section key={section.id} info={section} />))}
      </div>
      <FooterHome />
      <Cookies />
    </div>
  )
}




export default connect()(Home)
