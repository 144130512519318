import React from 'react';
import './Cookies.scss';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { AcceptCookies } from '../../services/actions/ui.actions';

import localization from "../../localization";

const Cookies = ({ ux, dispatch }) => {

  const { isCookiesAccept } = ux
  return (
    <div className={`cookies__container ${isCookiesAccept ? 'cookies_accept' : ''}`}>
      <div className="cookies__box--text">

        <h3>{localization.cookies.title}</h3>
        <p>{localization.cookies.text}<Link to={'/cookies'} target="_blank" rel="noopener noreferrer"> {localization.cookies.read}</Link> </p>
      </div>
      <div className="cookies__box--button">
        <button onClick={() => dispatch(AcceptCookies())}>{localization.cookies.buttonAccept}</button>
        {/* <button>{localization.cookies.buttonDeclied}</button> */}
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {

  return state
}
const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  }
}



export default connect(mapStateToProps, mapDispatchToProps)(Cookies)
